import axiosClient from "./clients/axiosClient";
const resource = "/municipality";
const lgId = process.env.VUE_APP_LOCAL_GOV_ID;

export default {
  getDetails(params = "") {
    return axiosClient.get(`${resource}/${lgId}${params}`);
  },
  getVoiceDetails(params = "") {
    return axiosClient.get(`${resource}-voice/${lgId}${params}`);
  },
  getAnswers(params = "") {
    return axiosClient.get(`${resource}/${lgId}/answers${params}`);
  },
  getAnswerTable(year, params = "") {
    return axiosClient.get(
      `${resource}/${lgId}/answer-table/year/${year}${params}`
    );
  },
};
